<template>
  <div class="banner">
    <div class="banner__above-title">Welcome package</div>
    <h1 class="banner__title">BET €15 <br />GET €10</h1>
    <h2 class="banner__subtitle">FREE BET</h2>
    <div class="banner__desc">
      New Players only. Max. Free Bet Token amount: €10.Minimum Deposit: €15.
      Minimum wagering on Sport to activate the Free Bet Token: €15
    </div>
    <Button
      title="Play Here"
      :target="true"
      url="https://heyspin.com/sport/?NeoDL=Registration"
    />
    <div class="banner__icons">
      <div>
        <img src="@/assets/images/banner/be-gamble-aware.svg" alt="BeGambleAware.org">
        <img src="@/assets/images/banner/18+.svg" alt="18+">
      </div>
      <p>Gambling can be addictive, please play responsibly.</p>
    </div>
  </div>
</template>

<script>
import Button from "./layout/Button.vue";
export default {
  name: "Banner",
  components: { Button }
};
</script>

<style lang="scss">
.banner {
  height: 700px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 98px 0;
  text-align: center;
  background: url("@/assets/images/banner/bg.jpg");

  @media (min-resolution: 2dppx) {
    background-image: url("@/assets/images/banner/bg@2x.jpg");
  }

  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  @media (max-width: $size-xl) {
    height: 467px;
    padding: 50px 0;
  }

  @media (max-width: $size-lg) {
    height: 374px;
    padding: 31px 0;
  }

  @media (max-width: $size-md) {
    height: 500px;
    min-width: 320px;
    margin: 0 auto;
    padding: 157px 0 16px;
    background: url("@/assets/images/banner/bg-mobile.jpg");
    @media (min-resolution: 2dppx) {
      background-image: url("@/assets/images/banner/bg-mobile@2x.jpg");
    }
    background-position: top;
    background-size: cover;
  }

  &__above-title {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 30px;
    line-height: 140%;
    text-transform: capitalize;
    color: var(--color-white);

    @media (max-width: $size-xl) {
      font-size: 20px;
    }

    @media (max-width: $size-lg) {
      font-size: 16px;
    }

    @media (max-width: $size-md) {
      font-size: 20px;
    }
  }

  &__title {
    font-size: 100px;
    line-height: 83%;
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--color-yellow);
    position: relative;
    z-index: 2;

    @media (max-width: $size-xl) {
      font-size: 67px;
    }

    @media (max-width: $size-lg) {
      font-size: 43px;
    }

    @media (max-width: $size-md) {
      font-size: 64px;
    }

    &:after {
      content: "BET €15 GET €10";
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: -1;
      width: 100%;
      color: #000;
      text-shadow: 1px 1px 0 #fff, 1px 0px 0 #fff, 1px 0px 0 #fff,
        1px 0px 0 #fff, 0px 0px 0 #fff, 0px 1px 0 #fff, -1px 1px 0 #fff,
        0px -1px 0 #fff, 1px 0px 0px rgb(206 89 55 / 0%);
    }
  }

  &__subtitle {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 700;
    font-size: 58px;
    line-height: 71px;
    text-transform: uppercase;
    color: #ffffff;

    @media (max-width: $size-md) {
      font-size: 36px;
    }
  }

  &__desc {
    font-family: "Open Sans";
    font-size: 12px;
    line-height: 140%;
    color: var(--color-grey-light);
    max-width: 364px;
    margin-bottom: 64px;

    @media (max-width: $size-xl) {
      font-size: 10px;
      max-width: 226px;
      margin-bottom: 37px;
    }

    @media (max-width: $size-lg) {
      font-size: 10px;
      max-width: 195px;
    }

    @media (max-width: $size-md) {
      font-size: 8px;
      max-width: 265px;
      margin-bottom: 0;
      position: absolute;
      bottom: 12px;
    }
  }

  &__icons {
    position: absolute;
    top: 11px;
    right: 100%;
    transform: translate(105%, 0);

    @media (min-width: $size-lg) {
      top: 84%;
      right: 2%;
      transform: translate(0,0);
    }

    @media (min-width: $size-xl) {
      top: 89%;
    }

    > div {
      display: flex;
      align-items: flex-end;
      margin-bottom: 2px;

      > img {
        width: 23px;

        @media (min-width: $size-lg) {
          width: auto;
        }

        &:not(:last-child) {
          width: 164px;
          margin-right: 5px;

          @media (min-width: $size-lg) {
            width: auto;
            margin-right: 8px;
          }
        }
      }
    }

    > p {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 7px;
      line-height: 1.4;
      color: var(--color-white);
      text-align: left;

      @media (min-width: $size-lg) {
        font-size: 12px;
      }
    }
  }
}
</style>
