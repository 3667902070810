<template>
  <div class="container">
    <div class="footer">
      <p class="footer__paragraph">
        One Free Bet Token will be awarded once you have made your first deposit
        and have bet €15 on Sports with odds of Evens (2.00) or greater. This
        bet must be settled within 14 days of bet placement to qualify for the
        Free Bet Token. The Free Bet Token can only be used on a bet with total
        odds of 4/5 (1.80) or higher. The maximum value of the Free Bet Token is
        €10. A maximum of one Free Bet Token will be awarded to each player in
        this promotion. Free Bet Token stake will not be included in any
        winnings. The winnings from the use of a Free Bet Token will not require
        any wagering and can be withdrawn right away. Singles and Straight
        accumulators will only be eligible to qualify for the Free Bet Token
        offer. System bets such as a Trixie, Yankee, “Lucky” Bet etc will not be
        eligible to qualify for the Free Bet Token offer. Cash Out bets will not
        count towards any qualifying requirements which may be needed for the
        Free Bet Token offer.
      </p>
      <p class="footer__paragraph">
        Bets placed with a Free Bet Token will not be
        eligible for cashout. A Free Bet Token cannot be exchanged for real
        money or any other bonuses. Free Bet Token can only be used on Sports
        and cannot be redeemed on any other online product (Casino or Live
        Casino etc.) Free Bet Token cannot be split. If you choose not to use
        the full amount of your Free Bet Token, the remaining amount will be
        forfeited The Free Bet Token offer will expire 14 days from the day it
        is activated in any new player’s account. Any amendments or
        modifications to, or cancellation of, the Free Bet Token offer will not
        apply to any player that has already accepted the Free Bet Token offer
        prior to the notification of any such changes to the Free Bet Token
        offer.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss">
.footer {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: var(--color-grey-light);
  padding-bottom: 80px;

  @media (max-width: $size-xl) {
    padding-bottom: 40px;
  }

  @media (max-width: $size-lg) {
    padding-bottom: 47px;
  }

  @media (max-width: $size-md) {
    padding-bottom: 20px;
    font-size: 8px;
  }

  &__paragraph {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
</style>
